import React, { useState,useContext } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CrmContext } from '../context/CrmContext';
import { LoginContext } from '../context/LoginContext';


export default function AlertDialog() {
  const {textDialog,openDialog,handleCloseDialog}:any = useContext(LoginContext)
 
  return (
    <div>
      {/* Your component content */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Response Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {textDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>handleCloseDialog()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
