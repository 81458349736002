import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/HomePage.css'

export default function HomePage({}) {
  return (
    <div className="homePage-container">
      <h1>HomePage</h1>
      <Link to="/login">
        <button>Go to Login</button>
      </Link>
    </div>
  )
}
