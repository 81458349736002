import React, { useState, useContext } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import '../styles/Login.css';
import { LoginContext } from '../context/LoginContext';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../component/AlertDialog';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate()

  const { Login }: any = useContext(LoginContext); // Get the Login function from the context


  
  const handleLogin = () => {
    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    // Construct the URL with query parameters
    const queryParams = ({ 
      email: email,
      password: password,
    });

    // Call the Login function from the context
    Login(queryParams,navigate); // Pass the query parameters as a string

  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <div className="input-container-Login">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
          />
          {emailError && <div className="error-message">{emailError}</div>}
        </div>
        <div className="input-container-Login password-input">
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="password-toggle-icon"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? <MdVisibilityOff /> : <MdVisibility />}
          </button>
        </div>
        <button className="login-button" onClick={handleLogin}>
          Log In
        </button>
      </div>
      <AlertDialog/>
    </div>
  );
}
