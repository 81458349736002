// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.postback-table{
    border: #1b1818 2px solid;
    background-color: #1d1b1b;
    border-radius: 20px;
    padding: 20px 0;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-top: 30px;
    filter: drop-shadow(2px 4px 6px black);
    font-size: 10px;
}

.copied-answer{
    font-size: 15px;
    border-radius: 20px;
    color: green;
    margin: auto;
    width: 100px;
    border: #302c2c 1px solid;
}

.postback-x-button{
    padding: 7px;
    position: absolute;
    border: white 1px solid;
    border-radius: 7px;
    top: 15px;
    right: 20px;
    transition: background-color 0.9s ease;
    cursor: pointer;
}

.postback-x-button:hover{
    
    background-color: red;
}

.copies{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    align-items: center;
}

.copie-ico{
   transition: all 0.3s ease-in-out;
   margin-left: 20px;
   font-size: 24px;
}

.copie-ico:hover{
    color: green;
    font-size: 27px;
 }`, "",{"version":3,"sources":["webpack://./src/styles/Clients.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,sCAAsC;IACtC,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,sCAAsC;IACtC,eAAe;AACnB;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;GACG,gCAAgC;GAChC,iBAAiB;GACjB,eAAe;AAClB;;AAEA;IACI,YAAY;IACZ,eAAe;CAClB","sourcesContent":[".postback-table{\n    border: #1b1818 2px solid;\n    background-color: #1d1b1b;\n    border-radius: 20px;\n    padding: 20px 0;\n    max-width: 900px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n    margin: auto;\n    margin-top: 30px;\n    filter: drop-shadow(2px 4px 6px black);\n    font-size: 10px;\n}\n\n.copied-answer{\n    font-size: 15px;\n    border-radius: 20px;\n    color: green;\n    margin: auto;\n    width: 100px;\n    border: #302c2c 1px solid;\n}\n\n.postback-x-button{\n    padding: 7px;\n    position: absolute;\n    border: white 1px solid;\n    border-radius: 7px;\n    top: 15px;\n    right: 20px;\n    transition: background-color 0.9s ease;\n    cursor: pointer;\n}\n\n.postback-x-button:hover{\n    \n    background-color: red;\n}\n\n.copies{\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    margin: auto;\n    align-items: center;\n}\n\n.copie-ico{\n   transition: all 0.3s ease-in-out;\n   margin-left: 20px;\n   font-size: 24px;\n}\n\n.copie-ico:hover{\n    color: green;\n    font-size: 27px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
