import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the type for your context value
import { LoginContextValue } from '../interface/LoginContextValue';
import { CrmContext } from './CrmContext';
//import { Link, Navigate } from 'react-router-dom';

// Create the context
export const LoginContext = createContext<LoginContextValue| undefined>(undefined);

// Define the props for your context provider
interface LoginContextProviderProps {
  children: ReactNode;
}

export function LoginContextProvider({ children }: LoginContextProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);//decide when to show dialog error
  const [textDialog, setTextDialog]:any = useState(null);//the dialog error
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTextDialog(null);
  };
  
  // const {setOpenDialog,  setTextDialog,TextDialog ,OpenDialog}:any = useContext(CrmContext)

  const Login = async (queryParams:any,navigate: any) => { 
    
    //test function to get user
    const requestBody = {
      username: queryParams.email,
      password: queryParams.password
    };
    
    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();  
      if (response.ok) {
        if (data.message == 0) { 
          setIsAuthenticated(true)
          sessionStorage.setItem('sourceID',data.sourceID);
          sessionStorage.setItem('isAuthenticated',data.token);
          navigate('/Crm');
        }   
      }
      else if (response.status === 401) {
        // Handle 401 Unauthorized response here
        setTextDialog(data.message)
        setOpenDialog(true);
      } else {
        console.error('Request failed with status:', response.status);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  const value: LoginContextValue = {
    Login,
    isAuthenticated,
    setIsAuthenticated,
    openDialog,
    setOpenDialog,
    textDialog,
    setTextDialog,
    handleCloseDialog
  };

  return (
    <LoginContext.Provider value={value}>
      {children}
    </LoginContext.Provider>
  );
}
