// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appBar {
     background-color: #d21985; 
    width: 80px;
  }
  
  .title {
    flex-grow: 1;
  }
  
  .button {
    margin-left: 16px;
  }
  
  .nav-container {
    background-color: aqua;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Nav.css"],"names":[],"mappings":"AAAA;KACK,yBAAyB;IAC1B,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".appBar {\n     background-color: #d21985; \n    width: 80px;\n  }\n  \n  .title {\n    flex-grow: 1;\n  }\n  \n  .button {\n    margin-left: 16px;\n  }\n  \n  .nav-container {\n    background-color: aqua;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
