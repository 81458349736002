// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body{
    background-color: #242424;
}

.crm-container{
    align-items: stretch;
    overflow-x: hidden; /* Hide horizontal scroll */
    background-color: #242424;
}

.toggle-buttons-crm{
    display: flex; 
    justify-content: space-around; 
    flex-wrap: wrap;
    margin: 10px;
}

.toggle-buttons-crm .active{
    background-color: rgb(43, 46, 226);
    padding: 12;
  
}
  
  .ag-theme-alpine-dark {
    flex: 1 1;
  }

  .ag-theme-alpine {
    --ag-column-select-indent-size: 40px
}

.ag-theme-alpine .ag-column-drop-cell {
    background-color: rgb(196, 130, 9);
}

.ag-theme-alpine .ag-column-drop-cell .ag-icon {
    color: white;
}

.ag-theme-alpine .ag-column-drop-cell-text {
    color: white;
    font-weight: bold;
}

.pagination-crm{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagination-crm button{
    margin: 10px;
}

.ag-cell{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Show full content on tooltip */
  .ag-cell-tooltip {
    white-space: normal;
    word-wrap: break-word;
  }

  
  
`, "",{"version":3,"sources":["webpack://./src/styles/Crm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oBAAoB;IACpB,kBAAkB,EAAE,2BAA2B;IAC/C,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,WAAW;;AAEf;;EAEE;IACE,SAAO;EACT;;EAEA;IACE;AACJ;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA,iCAAiC;EACjC;IACE,mBAAmB;IACnB,qBAAqB;EACvB","sourcesContent":[".body{\n    background-color: #242424;\n}\n\n.crm-container{\n    align-items: stretch;\n    overflow-x: hidden; /* Hide horizontal scroll */\n    background-color: #242424;\n}\n\n.toggle-buttons-crm{\n    display: flex; \n    justify-content: space-around; \n    flex-wrap: wrap;\n    margin: 10px;\n}\n\n.toggle-buttons-crm .active{\n    background-color: rgb(43, 46, 226);\n    padding: 12;\n  \n}\n  \n  .ag-theme-alpine-dark {\n    flex: 1;\n  }\n\n  .ag-theme-alpine {\n    --ag-column-select-indent-size: 40px\n}\n\n.ag-theme-alpine .ag-column-drop-cell {\n    background-color: rgb(196, 130, 9);\n}\n\n.ag-theme-alpine .ag-column-drop-cell .ag-icon {\n    color: white;\n}\n\n.ag-theme-alpine .ag-column-drop-cell-text {\n    color: white;\n    font-weight: bold;\n}\n\n.pagination-crm{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.pagination-crm button{\n    margin: 10px;\n}\n\n.ag-cell{\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  \n  /* Show full content on tooltip */\n  .ag-cell-tooltip {\n    white-space: normal;\n    word-wrap: break-word;\n  }\n\n  \n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
