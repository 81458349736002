// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homePage-container{
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertical centering */
    align-items: center; /* Horizontal centering */
    height: 100vh; 
}`, "",{"version":3,"sources":["webpack://./src/styles/HomePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB,EAAE,uBAAuB;IAChD,mBAAmB,EAAE,yBAAyB;IAC9C,aAAa;AACjB","sourcesContent":[".homePage-container{\n    display: flex;\n    flex-direction: column;\n    justify-content: center; /* Vertical centering */\n    align-items: center; /* Horizontal centering */\n    height: 100vh; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
