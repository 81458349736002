import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import '../styles/Nav.css';
import { Link, useNavigate  } from 'react-router-dom';
import { LoginContext } from '../context/LoginContext';

export default function Nav() {
  const {setIsAuthenticated }: any = useContext(LoginContext);
 
  const navigate = useNavigate(); 
  const handleLogout = () => {
    sessionStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('sourceID');
    setIsAuthenticated(false);
    navigate("/login");
  };

  return (
    <div className='nav-container'>
      <AppBar position="static" className='appBar' sx={{ maxWidth: '100%', margin: '0 auto' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }} className='title'>
            Crm
          </Typography>
          <Link to={'/FilterBtn'} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color="inherit" sx={{ marginLeft: '16px' }} className='button'>
              FilterBtn
            </Button>
          </Link>
          <Link to={'/Crm'} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color="inherit" sx={{ marginLeft: '16px' }} className='button'>
              Crm
            </Button>
          </Link>
          <Link to={'/Clients'} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color="inherit" sx={{ marginLeft: '16px' }} className='button'>
              Clients
            </Button>
          </Link>
          <Button color="inherit" sx={{ marginLeft: '16px' }} className='button' onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
