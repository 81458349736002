import React, { useContext, useState } from 'react';
import { CrmContext } from '../context/CrmContext';
import '../styles/FilterBtn.css';

export default function FilterBtn() {
  const { columnDefs, setVisibleColumns, appliedFilters, setAppliedFilters }: any = useContext(CrmContext);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [filterName, setFilterName] = useState('');
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const handleFieldClick = (field: string) => {
    setSelectedFields(prevFields =>
      prevFields.includes(field) ? prevFields.filter(f => f !== field) : [...prevFields, field]
    );
  };

  const handleApplyFilter = () => {
    if (filterName.trim() !== '') {
      const updatedFilters = [...appliedFilters, { name: filterName, fields: selectedFields }];
      setAppliedFilters(updatedFilters);
      localStorage.setItem('appliedFilters', JSON.stringify(updatedFilters));
      setSelectedFields([]);
      setFilterName('');
    }
  };

  const handleDeleteFilter = (index: number) => {
    const updatedFilters = appliedFilters.filter((_filter: any, i: number) => i !== index);
    setAppliedFilters(updatedFilters);
    localStorage.setItem('appliedFilters', JSON.stringify(updatedFilters));
  };


  const handleEditFilter = (index: number) => {
    const filterToEdit = appliedFilters[index];
    setFilterName(filterToEdit.name);
    setSelectedFields(filterToEdit.fields);
    setEditIndex(index);
  };

  const handleUpdateFilter = () => {
    if (editIndex !== null && filterName.trim() !== '') {
      const updatedFilters = [...appliedFilters];
      updatedFilters[editIndex] = { name: filterName, fields: selectedFields };
      setAppliedFilters(updatedFilters);
      localStorage.setItem('appliedFilters', JSON.stringify(updatedFilters));
      setSelectedFields([]);
      setFilterName('');
      setEditIndex(null); // Reset editIndex after updating
    }
  };

  return (
    <div className="filter-container">
      <h2 className="filter-header">Select Fields to Filter</h2>
      <div className="filter-buttons-container">
        {columnDefs.map((column: any) => (
          <button key={column.field} className={`filter-button ${selectedFields.includes(column.field) ? 'active' : ''}`} onClick={() => handleFieldClick(column.field)}>
            {column.headerName}
          </button>
        ))}
      </div>
      <div className="filter-actions">
        <input type="text" className="filter-input" value={filterName} placeholder="Enter filter name" onChange={e => setFilterName(e.target.value)} />
        <button className="filter-button-apply" onClick={handleApplyFilter}>
          Apply Filter
        </button>
      </div>
      <h3 className="filter-header">Applied Filters</h3>
      {/* <ul className="applied-filters">
        {appliedFilters.map((filter: { name: string; fields: string[] }, index: number) => (
          <li key={index}>
            {filter.name}: {filter.fields.join(', ')}{' '}
            <button className="filter-button" onClick={() => handleDeleteFilter(index)}>
              Delete
            </button>
          </li>
        ))}
      </ul> */}
      <ul className="applied-filters">
        {appliedFilters.map((filter: { name: string; fields: string[] }, index: number) => (
          <li key={index}>
            {filter.name}: {filter.fields.join(', ')}{' '}
            <button className="filter-button" onClick={() => handleEditFilter(index)}>
              Edit
            </button>
            <button className="filter-button" onClick={() => handleDeleteFilter(index)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
      {editIndex !== null && (
        <div className="edit-filter">
          <input
            type="text"
            className="filter-input"
            value={filterName}
            placeholder="Edit filter name"
            onChange={(e) => setFilterName(e.target.value)}
          />
          <button className="filter-button-apply" onClick={handleUpdateFilter}>
            Update Filter
          </button>
        </div>
      )}
    </div>
  );
}
