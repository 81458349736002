import React, {useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import '../src/styles/App.css';
import { CrmContextProvider } from './context/CrmContext';
import Nav from './component/Nav';
import Crm from './pages/Crm';
import Login from './pages/Login';
import { LoginContextProvider } from './context/LoginContext';
import FilterBtn from './pages/FilterBtn';
import HomePage from './pages/HomePage';
import { ClientContextProvider } from './context/ClientContext';
import Clients from './pages/Clients';


function PrivateRoute({ element, ...rest }: any) {
  const navigate = useNavigate();
  const isAuthenticated = sessionStorage.getItem('isAuthenticated');
  
  useEffect(() => {
    if (!isAuthenticated) { navigate('/login');  }// Redirect to login if not authenticated
  }, [isAuthenticated, navigate]);
  // Render the protected route if authenticated
  return isAuthenticated ? element : null;
}


function App() {
  return (
    <>
      <BrowserRouter>
          <LoginContextProvider>
            <CrmContextProvider>
        <ClientContextProvider>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
              
                {/* Use PrivateRoute component to wrap protected routes */}
                <Route path="/Crm" element={<> <Nav /> <PrivateRoute element={<Crm />} /> </>} />
                <Route path="/FilterBtn" element={<> <Nav/> <PrivateRoute element={<FilterBtn />} /> </>} />
                <Route path="/nav" element={<Nav/> } />
                <Route path="/Clients" element={  <><Nav/> <PrivateRoute element={<Clients/>}/> </>} />
              
              </Routes>
        </ClientContextProvider>
            </CrmContextProvider>
          </LoginContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
